import { React, useState } from 'react';
import styles from './Website.css';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import DjPanel from './Djpanel';
import bookCover from './assetts/Cover.jpg';
import ContactForm from './ContactForm';
import bookPdf from './assetts/Histories Greatest Pusshounds.pdf';
import djfracking from './assetts/DJ4.png'

const LandingPage = () => {
    const [selectedPayer, setSelectedPayer] = useState(null);

    const handlePayerSelect = (payer) => {
      setSelectedPayer(payer);
    };

    return (
        <div className="landing-page">
            <Helmet>
                <title>DJFracking</title>
                <meta name="description" content="The ultimate enigma" />
                <meta name="keywords" content="DJFracking, DJ" />
                <meta property="og:title" content="DJFracking" />
                <meta property="og:description" content="The ultimate enigma" />
            </Helmet>
            <div className='logo-container'>
                <div className="logo-bg"></div>
            </div>
            <div className="main-content">
                <main>
                    <div className="block">
                        <DjPanel />
                    </div>

                    <section>
                    <h2>DJFracking's Favorite Songs</h2>
                    <div className="underline"></div>
                    <div className="spotify-playlist">
                <iframe 
                    style={{ borderRadius: '12px' }}
                    src="https://open.spotify.com/embed/playlist/0ioBuRyZIsCAXCR2jmOuDo?utm_source=generator&theme=0"
                    width="100%" 
                    height="452" 
                    frameBorder="0" 
                    allowfullscreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy"
                    title="Spotify Playlist"
                ></iframe>

            </div>

                </section>
                {/* <section className="book-section">
                    <h2>That Time DJFracking Wrote a Book</h2>
                    <div className="underline"></div>
                    <a href={bookPdf} download>
                        <img className="bookImage" src={bookCover} alt="DJFracking's Book Cover" />
                    </a>
                    <p>Click on the book cover to download the PDF.</p>
                </section> */}
                <section className="services-section">
                <h2>Services</h2>                    
                <div className="underline"></div>
                <ul className="services-list">
                    <li className="service-item"><span>DJ</span></li>
                    <li className="service-item"><span>Public Speaking</span></li>
                    <li className="service-item"><span>Verizon Certified Internet Installation</span></li>
                    <li className="service-item"><span>Hot-tub Mechanic</span></li>  {/* Added as an example */}
                    <li className="service-item"><span>Event Management</span></li>  {/* Added as an example */}
                    <li className="service-item"><span>Nuclear Disaster Relief</span></li>  {/* Added as an example */}

                </ul>
            </section>
            <section className="about-section">
            <div className="left-div">
            <div className="about-image">
                <img src={djfracking} alt="DJFracking" />
            </div>
            </div>
            <div className="right-div">
                <div className="about-content">
                <p>
                    DJ Fracking is an enigmatic figure, whose true identity remains hidden behind the sonic landscapes he creates and the vibrant atmospheres he conjures on stage. Unseen yet profoundly felt, he is a spirit that speaks to the soul through music, an elusive artist who defies the conventional confines of ordinary life.
                    </p>
                    <p>
                    Beyond his musical persona, DJ Fracking is a true renaissance man. His polymathic prowess spans an astonishing array of fields: he is an engineer, a scientist, a mathematician, a philanthropist, a motorcycle enthusiast, a chef, a romantic, a painter, a nuclear disaster recovery specialist, a writer, a photographer, a businessman, a pilot, a hot tub mechanic, a hunter and fisherman, a chainsaw expert, and, of course, a DJ. He embodies the ideal of a universal person, embracing a multitude of passions with equal fervor.
                    </p>
                    <p>
                    When night descends, DJ Fracking steps into his realm, crafting sonic symphonies that resonate with the heartbeat of the crowd. His performances are legendary, known far and wide for their grandeur. His entrances, as dramatic and mesmerizing as the music he delivers, never fail to captivate audiences.
                    </p>
                    <p>
                    A scholar by passion, Fracking imbues each of his ventures with his multifaceted personality. The breadth and depth of his knowledge echo in his music, bringing a unique touch to every performance.
                    </p>
                    <p>
                    Yet, for all his accolades, DJ Fracking remains shrouded in mystery. It is this combination of enigma and talent, secrecy and spectacle, that makes him not just a DJ, but a phenomenon—a spirit that flows through the crowds, bringing them what they crave and more.
                    </p>
                    <p>
                    His journey is a testament to the power of music and the pursuit of diverse passions. It is a celebration of the unseen and the unexplored—a tribute to DJ Fracking, the legend.
                    </p>

            </div>
           </div>
        </section>
                
            <section>
                <h2>Contact Me</h2>
                <div className="underline"></div>
                <ContactForm />
            </section>

                </main>
            </div>
            
            <Footer />
        </div>
    );
};

export default LandingPage;
